<template>
  <div class="main">
    <img class="bg-img" src="../../assets/goldplan/bg-img.png">
    <div class="form-main">
      <div class="form-main-top">
        <img src="../../assets/goldplan/form-top.png">
        <span>支付完成</span>
      </div>
      <div class="form-main-body">
        <div class="form-line">
          <div class="form-line-left">
            <img src="../../assets/goldplan/money.png">
            <span>交易金额：</span>
          </div>
          <span class="font-weight-500">￥{{ amount }}</span>
        </div>
        <div class="form-line">
          <div class="form-line-left">
            <img src="../../assets/goldplan/order.png">
            <span>订单编号：</span>
          </div>
          <span class="font-color-74">{{ orderNo }}</span>
        </div>
        <div class="form-line">
          <div class="form-line-left">
            <img src="../../assets/goldplan/progrem.png">
            <span>交易项目：</span>
          </div>
          <span class="font-color-74">{{ productDesc }}</span>
        </div>
        <div class="margin-top-13 font-color-74">交易正在处理中, 根据网络状况交易可能会有稍许延迟，请耐心等待</div>
        <button @click="returnBtn">返回商户</button>
      </div>
    </div>
  </div>
</template>

<script>
// import 'https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js'
import './goldplan.js'
import { queryWeXinOrderTrade } from '@/api/password'
export default {
  name: 'GoldPlan',
  data() {
    return {
      amount: '',
      orderNo: '',
      productDesc: ''
    }
  },
  beforeMount() {},
  mounted() {
    this.getParameter()
  },
  methods: {
    // url参数解析
    getQueryVariable(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    },
    getParameter() {
      var out_trade_no = this.getQueryVariable('out_trade_no')
      queryWeXinOrderTrade(out_trade_no).then(res => {
        res.data.orderNo = this.orderNo
        res.data.productDesc = this.productDesc
        res.data.amount = this.amount
      })
    },
    returnBtn() {
      this.$router.push('/orderList')
    }
  }
}
</script>

<style lang="scss" scoped>
.font-weight-500 {
  font-weight: 500;
}
.font-color-74 {
  color: #747474;
}
.margin-top-13 {
  margin-top: 13px;
}
.bg-img {
  width: 100%;
}
.form-main {
  position: fixed;
  font-size: 14px;
  top: 23px;
  padding: 0 18px;
  width: 100%;
}
.form-main-top {
  display: flex;
  align-items: center;
}
.form-main-top img {
  width: 24px;
}
.form-main-top span {
  font-size: 18px;
  color: #fff;
  margin-left: 7px;
}
.form-main-body {
  width: 100%;
  margin-top: 16px;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(21, 131, 213, 0.05);
  border-radius: 10px;
}
.form-line {
  height: 38px;
  line-height: 38px;
  display: flex;
  border-bottom: 1px dashed #e9e9e9;
}
.form-line-left {
  display: flex;
  font-size: 12px;
  align-items: center;
}
.form-line-left img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
button {
  width: 100%;
  height: 36px;
  border: 0;
  background: #2f9ff2;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: #fff;
  margin-top: 13px;
}
</style>
